<div class="p-4 text-center">
  <img
    *ngIf="!isEdit"
    class="w-logo"
    src="../assets/png/Broadfactor Logo- Preto.png"
    alt="logo broadfactor"
  />
</div>

<router-outlet></router-outlet>
