<div class="img">
  <div class="page">
    <div class="informations-container">
      <div class="content-container">
        <div class="title-container">
          <h1>Bem-vindo a Broadfactor!</h1>
        </div>
        <div class="description">
          <p class="text">
            Somos uma plataforma digital de antecipação de recebíveis que nasceu
            com o propósito de levar prosperidade aos empreendedores, ajudamos a
            transformar sonhos em negócios de sucesso.
          </p>
          
        </div>

        <span class="privacy-policy-text"
          >Ao continuar você concorda com a nossa
          <a
            href="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/cedente/Pol%C3%ADtica+de+Privacidade+Broadfactor.pdf"
            target="_blank"
            >Política de Privacidade</a
          ></span
        >
        <br />
        <button
          mat-flat-button
          class="button-next"
          color="accent"
          (click)="navigatePreRegister()"
        >
          Continuar
        </button>
      </div>
    </div>

    <img
      class="mobile-image"
      src="https://broadfactor-lite-qa.s3.amazonaws.com/Pre-cadastro.png"
      alt="foguete decolando"
    />
  </div>
</div>
